// vendors
import React from 'react';
import { graphql } from 'gatsby';

// components
import Layout from '../components/Layout';
import MediaText from '../components/MediaText';
import PageHeader from '../components/PageHeader';
import Heading from '../components/Heading';
import { generateSoftGoodAccessoryUrl } from '../../lib/generateSoftGoodAccessoryUrl';
import ProductsGrid from '../components/ProductsGrid';
import ProductCard from '../components/ProductCard';

const translations = [
  {
    locale: 'en',
    url: '/en/soft-goods-and-accessories/',
  },
];

const TextilesEtAccessoiresPage = ({ data }) => {
  let {
    softGoodsAccessories: { nodes: softGoodsAccessories = [] },
  } = data;

  softGoodsAccessories = softGoodsAccessories.map((item) => ({
    name: item.name.fr,
    price: item.price,
    image: {
      gatsbyImageData: item.featuredImage?.asset?.localFile?.childImageSharp,
    },
    href: generateSoftGoodAccessoryUrl('fr', item.slug.current),
  }));

  return (
    <Layout translations={translations}>
      <article className="space-y-48">
        <PageHeader>
          Textiles <br />
          et Accessoires
        </PageHeader>

        <section className="container">
          <ProductsGrid>
            {softGoodsAccessories.map((item) => (
              <ProductCard
                key={item.href}
                name={item.name}
                href={item.href}
                image={item.image}
              />
            ))}
          </ProductsGrid>
        </section>
      </article>
    </Layout>
  );
};

export default TextilesEtAccessoiresPage;

export const query = graphql`
  query {
    readyToPlayImage: file(name: { eq: "PellerinGuitares_025" }) {
      childImageSharp {
        gatsbyImageData(width: 1536)
      }
    }
    softGoodsAccessories: allSanitySoftGoodAccessory(
      sort: { fields: orderRank, order: ASC }
    ) {
      nodes {
        slug {
          current
        }
        name {
          en
          fr
        }
        price
        featuredImage {
          asset {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 744
                  height: 1044
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
          }
        }
      }
    }
  }
`;
